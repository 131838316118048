import React, { Component } from 'react'
import { setBodyFixed, qs, qsa } from '../utils/lib'
import { gsap, Expo } from 'gsap'
import Store from './store'
import Sync from './cart_sync';

export default class MiniCart extends Component {

  state = {
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    translations: JSON.parse(this.props.translations),
    animating: false,
    loading: false,
    total: 0,
    totalQuantity: 0
  }

  async componentDidMount() {
    console.log('minicart')
    gsap.to(this.refs.wrapper, {
      xPercent: 100,
      duration: 0
    })
    Store.linkState(this, ['cart_count'])
    Store.linkState(this, ['cart_open'])
    Store.linkState(this, ['cart_items'])
    Store.linkState(this, ['close_cart'])
    Store.linkState(this, ['cart_total'])
    Store.linkState(this, ['checkout'])


    this.calcTotal()
    this.setButton()



  }

  calcItems() {
    const cartItemsQty = this.state.cart_items.map((cartItem) => {
      return cartItem.quantity
    })
    if (cartItemsQty.length > 0) {
      this.setState({ totalQuantity: cartItemsQty.reduce((a, b) => a + b) })
    }
  }

  toggle() {
    const layer = qs('.header-sidebar-layer')
    if (this.state.animating || this.state.cart_total == 0) return
    if (this.state.cart_open) {
      this.close()
      layer.classList.remove("active")
    } else {
      this.open()
      layer.classList.add("active")
    }
  }
  open() {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";
    document.querySelector(".main_header_pre").style.zIndex = -1;
    const layer = qs('.header-sidebar-layer')
    layer.classList.add("active")
    const tl = gsap.timeline()
    this.setState({ animating: true })
    this.refs.wrapper.classList.remove('close')
    tl
      .to(this.refs.wrapper, {
        xPercent: 100,
        opacity: 1,
        duration: 0
      })
      .to(this.refs.wrapper, {
        xPercent: 0,
        ease: "power2.inOut",
        duration: .6,
        onComplete: () => {
          this.setState({ animating: false, cart_open: true })
        }
      })
  }

  close() {



    const html = qs('html')
    const layer = qs('.header-sidebar-layer')
    layer.classList.remove("active")
    html.style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";
    document.querySelector(".main_header_pre").style.zIndex = 'initial';
    const tl = gsap.timeline()
    this.setState({ animating: true })
    tl
      .to(this.refs.wrapper, {
        xPercent: 0,
        duration: 0
      })
      .to(this.refs.wrapper, {
        xPercent: 100,
        ease: "power2.inOut",
        duration: .6,
        onComplete: () => {
          this.setState({ animating: false, cart_open: false })
          html.classList.remove('is_hidden')
          document.body.classList.remove('is_hidden')
          this.refs.wrapper.classList.add('close')


        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (Store.getProps(['add_to_cart']).add_to_cart) {
      this.open()
      Store.set("add_to_cart", false)
      this.calcTotal()


    }

    if (this.state.close_cart) {
      this.close()
      this.setState({
        close_cart: false
      })
    }
    this.syncCart()

  }

  componentWillUnmount() {
  }


  add(i) {
    this.setButton()
    let items = this.state.cart_items
    console.log(items[i], items[i].size.qty)
    if (items[i].quantity + 1 > items[i].size.qty) {
      return false
    }
    items[i].quantity += 1
    const qty_string = qsa(".js-item-quantity")
    qty_string[i].innerHTML = items[i].quantity
    console.log(items[i])
    this.setState({
      cart_items: items
    })
    this.calcTotal()
    this.calcItems()
  }

  remove(i) {
    this.setButton()
    let items = this.state.cart_items
    items[i].quantity -= 1
    if (items[i].quantity == 0) {
      items.splice(i, 1)
      this.setState({
        cart_items: items
      })

    } else {
      this.setState({
        cart_items: items
      })
    }
    this.calcTotal()


  }

  delete(i) {
    let items = this.state.cart_items
    items[i].quantity = 0
    if (items[i].quantity == 0) {
      const el = document.querySelectorAll(".c-minicart__row")[i]
      gsap.to(el, {
        duration: .4,
        opacity: 0,
        onComplete: () => {
          items.splice(i, 1)
          this.setState({
            cart_items: items
          })
          gsap.to(el, {
            duration: 0,
            opacity: 1
          })
        }
      })

    }

    this.calcTotal()

  }

  calcPrice() {
    let priceArray = []
    let total

    this.state.cart_items.forEach((el, i) => {
      let sum = parseFloat(el.price) * el.quantity

      priceArray.push(sum)
    })

    return priceArray.reduce((a, b) => a + b, 0)
  }

  syncCart() {
    localStorage.setItem('lc_cart', JSON.stringify(this.state.cart_items));
    new Sync(false)

  }

  calcTotal() {
    let total = 0
    this.state.cart_items.forEach((el, i) => {
      let sum = el.price * el.quantity
      total = total + sum
    })
    Store.set("cart_total", total)
    if (total == 0 && this.state.cart_open) {
      this.close()
    }
    this.calcItems()
  }
  setButton() {
    // let items = this.state.cart_items
    // items.forEach((item,i) => {
    //   if (item.quantity == item.size.qty) {
    //     qsa('button[data-item="add"]')[i].setAttribute('disabled','')
    //     return
    //   } else {
    //     qsa('button[data-item="add"]')[i].removeAttribute('disabled')
    //   }
    // })
  }



  render() {

    return (
      <>
        <button className="cart icon-link" onClick={this.open.bind(this)}>
          <img loading="lazy" className="svg" src="/icons/basket.svg" />
          <p className="h9 brown c-dark">{this.state.translations.bag_icon}</p>
          <span className='cart-counter'>{this.state.totalQuantity}</span>
        </button>

        <aside className="header-sidebar-layer"></aside>
        <section className="header-sidebar header-cart close bg-main" ref="wrapper">

          <button onClick={this.close.bind(this)} className="closeX">
            <img loading="lazy" className="" src="/icons/Close.svg" alt="" />
          </button>

          <h3 className="header-cart_title h5 c-dark">{`${this.state.translations.bag} (${this.state.totalQuantity})`}</h3>
          <div className='header-cart_wrapper'>
            {
              this.state.cart_items.length > 0 ? (
                this.state.cart_items.map((el, i) => (

                  <section key={i} className="header-cart-product">
                    {el.image && (
                      <img loading="lazy" className="header-cart-product_img" src={el.image} alt={el.title} />

                    )}
                    <div className="header-cart-product_info-cont">
                      <div className="header-cart-product_info-top">
                        <span>

                          <h5 className="header-cart-product_name sainte medium h7 c-dark ">{el.title}</h5>
                          <h5 className="header-cart-product_name sainte light-italic h7 c-dark ">{el.subtitle}</h5>
                          <p className="brown light h9">{el.size.size}</p>
                        </span>
                        <p className="header-cart-product_price brown h7">€{el.price.toFixed(2)}</p>
                      </div>
                      <div className="header-cart-product_info-bottom">
                        <button onClick={this.delete.bind(this, i)} className="upcase button-link button-link-small brown h7">{this.state.translations.delete}</button>
                        {el.size.upc != "0CHRISTMASWRAP" && (
                          <div className="header-cart-product_counter">
                            <button onClick={this.remove.bind(this, i)} data-item="remove">
                              <img loading="lazy" className="header-cart-product_minus" src="/icons/minus_circle.svg" alt="" />
                            </button>
                            <p className="js-item-quantity brown light h7">{this.state.cart_items[i].quantity}</p>
                            <button onClick={this.add.bind(this, i)} data-item="add">
                              <img loading="lazy" className="header-cart-product_plus" src="/icons/plus_circle.svg" alt="" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>

                ))
              ) : (
                <>
                  <br />
                  <p className='c-dark h6'>{this.state.translations.empty}</p>
                  <br />
                  <button onClick={this.close.bind(this)} className="text-left h7 underline here brown c-dark">{this.state.translations.back}</button>
                </>
              )
            }
          </div>
          {
            this.state.cart_items.length > 0 && (
              <div className="header-cart_checkout">
                <div className="header-cart_subtotal">
                  <div className="brown light h8 ">
                    <p className="subtotal upcase c-dark">{this.state.translations.subtotal}</p>
                  </div>
                  <div>
                    <p className="header-cart_price brown h7 c-dark">€ {this.state.cart_total.toFixed(2)}</p>
                  </div>
                </div>
                <a href={this.props.checkout} className="header-cart_button button h8 upcase brown button-bg-visible button-auto c-dark">{this.state.translations.go}</a>
                <div>
                  <button onClick={this.close.bind(this)} className="brown light h9 underline c-dark">{"Continue Shopping"}</button>
                </div>
              </div>
            )
          }

        </section>
      </>
    )
  }
}










